import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import { Container, Row, Col } from "react-bootstrap"
import Swiper from "react-id-swiper"
import "react-id-swiper/lib/styles/css/swiper.css"
import { LazyLoadComponent } from "react-lazy-load-image-component"

// LOGIN ICONS
import Vfficient from "../../images/benefits/vfficient.png"
import Humana from "../../images/benefits/humana.png"
import Metlife from "../../images/benefits/metlife.png"
import Vsp from "../../images/benefits/vsp.png"
import Aetna from "../../images/benefits/aetna.png"
import Unum from "../../images/benefits/unum.png"
import Tfhour from "../../images/benefits/24hour.png"
import LTTrust from "../../images/benefits/lttrust.png"
import Anthem from "../../images/benefits/anthem.png"

// import BenefitsDiagram from "../images/benefits-diagram-v2.svg"
import BenefitsDiagram from "../../components/svg/ContractorBenefitsNew"

const params = {
  slidesPerView: 4,
  spaceBetween: 15,
  loop: true,
  speed: 700,
  pagination: {
    el: ".swiper-pagination",
    type: "bullets",
    clickable: true,
  },
  navigation: {
    nextEl: "#logins-next",
    prevEl: "#logins-prev",
  },
  breakpoints: {
    1200: {
      slidesPerView: 4,
    },
    992: {
      slidesPerView: 3,
    },
    475: {
      slidesPerView: 1,
    },
  },
}

const TechnologyBenefits = location => {
  return (
    <Layout location={location} page="technology-benefits">
      <div className="benefits-heading">
        <div className="container">
          <h1 className="text-blue text-center mb-5 relative">Benefits</h1>
          <h3 className="relative">It pays to work with PeerSource.</h3>
          <Link className="common-btn block relative" to="/jobs">
            Grow Your Career
          </Link>
        </div>
      </div>
      <div className="benefits-page" style={{ paddingTop: "200px" }}>
        <h2 className="text-center benefits-flair mb-5 text-dark">
          Experience a Better Recruiting Partnership
        </h2>

        <Container style={{ paddingBottom: "100px" }}>
          <Row className="justify-content-center">
            <Col lg={9}>
              {/* <h4 className="mb-5">
                Without our talented candidates like you, there would be no
                PeerSource! When you choose PeerSource to help you find the
                perfect position, we are committed to offer the best recruiting
                experience you have ever received.
              </h4> */}
              <p className="mb-5">
                At PeerSource, it’s not just about finding you a new job. We
                take the time to listen to what really matters – your
                motivations, skill sets, and career goals. We take the time to
                listen to what really matters – your motivations,skill sets, and
                career goals. We even offer resume coaching and interview
                preparation, and help you transition into your new role. It’s
                our commitment to build long-term relationships with all our
                candidates by maintaining regular contact, even after you’ve
                been placed on assignment.
              </p>
              <p className="mb-5">
                As a full-time W2 PeerSource consultant, you'll receive access
                to our comprehensive and compelling benefits. We’ll even
                contribute to your health benefit costs and match your 401k
                contribution!
              </p>
              <Link className="common-btn block relative" to="/contact-us">
                Reach a Recruiter
              </Link>
            </Col>
          </Row>
        </Container>

        <Container
          fluid
          className="benefit-contactor"
          style={{ padding: "100px 0", backgroundColor: "#F9F9F9" }}
        >
          <Container className="text-center">
            <h1 className="text-center benefits-flair mb-5 text-dark text-center">
              PEERSOURCE CONTRACTOR BENEFITS
            </h1>
            <BenefitsDiagram />
          </Container>
        </Container>

        <Container
          fluid
          className="benefit-logins"
          style={{ padding: "100px 0" }}
        >
          <Container>
            <h1 className="text-center benefits-flair mb-5 text-white text-center">
              BENEFIT LOGINS
            </h1>

            <LazyLoadComponent>
              <div className="benefit-logins-swiper-wrapper">
                <div className="logins-nav-buttons">
                  <div
                    id="logins-next"
                    tabindex="0"
                    role="button"
                    aria-label="Next slide"
                  ></div>
                  <div
                    id="logins-prev"
                    tabindex="0"
                    role="button"
                    aria-label="Previous slide"
                  ></div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-11">
                    <Swiper {...params}>
                      <div
                        className="blogin-container text-center"
                        style={{ position: "relative" }}
                      >
                        <img
                          className="img-fluid"
                          src={Vfficient}
                          alt="Vfficient"
                        />
                        <p>
                          Employee Login – View paystubs, access your W2, and
                          complete open enrollment.
                        </p>

                        <a
                          href="https://vns.prismhr.com/vns/auth/#/login?lang=en"
                          target="_blank"
                          rel="noreferrer"
                          className="blue-btn border-none"
                          type="submit"
                        >
                          Login
                        </a>
                      </div>
                      {/* 
                      <div
                        className="blogin-container text-center"
                        style={{ position: "relative" }}
                      >
                        <img className="img-fluid" src={Humana} alt="Humana" />
                        <p>
                          Health Plan Participants-Find a provider, access
                          wellness plan resources, and get RX information.
                        </p>
                        <a
                          href="https://account.humana.com/"
                          target="_blank"
                          rel="noreferrer"
                          className="blue-btn border-none"
                          type="submit"
                        >
                          Login
                        </a>
                        <button className="blue-btn border-none" type="submit">
                          Login
                        </button>
                      </div> */}

                      <div
                        className="blogin-container text-center"
                        style={{ position: "relative" }}
                      >
                        <img className="img-fluid" src={Anthem} alt="Anthem" />
                        <p>
                          Health Participants – Find a Provider, print ID cards,
                          and process claims.
                        </p>
                        <a
                          href="https://www.anthem.com/account-login/"
                          target="_blank"
                          rel="noreferrer"
                          className="blue-btn border-none"
                          type="submit"
                        >
                          Login
                        </a>
                        {/* <button className="blue-btn border-none" type="submit">
                          Login
                        </button> */}
                      </div>

                      <div
                        className="blogin-container text-center"
                        style={{ position: "relative" }}
                      >
                        <img
                          className="img-fluid"
                          src={Metlife}
                          alt="24 Hour Flex"
                        />
                        <p>
                          Dental Participants – Find a Provider, print ID cards,
                          and process claims.
                        </p>
                        <a
                          href="https://identity.metlife.com/public/login"
                          target="_blank"
                          rel="noreferrer"
                          className="blue-btn border-none"
                          type="submit"
                        >
                          Login
                        </a>
                        {/* <button className="blue-btn border-none" type="submit">
                          Login
                        </button> */}
                      </div>

                      <div
                        className="blogin-container text-center"
                        style={{ position: "relative" }}
                      >
                        <img
                          className="img-fluid"
                          src={Vsp}
                          alt="24 Hour Flex"
                        />
                        <p>
                          Vision Participants – Find a Provider, print ID cards,
                          and process claims.
                        </p>
                        <a
                          href="https://www.vsp.com/my-account"
                          target="_blank"
                          rel="noreferrer"
                          className="blue-btn border-none"
                          type="submit"
                        >
                          Login
                        </a>
                        {/* <button className="blue-btn border-none" type="submit">
                          Login
                        </button> */}
                      </div>

                      {/* <div
                        className="blogin-container text-center"
                        style={{ position: "relative" }}
                      >
                        <img
                          className="img-fluid"
                          src={Metlife}
                          alt="MetLife"
                        />
                        <p>
                          Dental Plan Participants-Find a Dentist and process
                          claims.
                        </p>
                        <a
                          href="https://identity.metlife.com/public/login"
                          target="_blank"
                          rel="noreferrer"
                          className="blue-btn border-none"
                          type="submit"
                        >
                          Login
                        </a>
                        <button className="blue-btn border-none" type="submit">
                          Login
                        </button>
                      </div> */}

                      {/* <div
                        className="blogin-container text-center"
                        style={{ position: "relative" }}
                      >
                        <img className="img-fluid" src={Aetna} alt="Aetna" />
                        <p>
                          Vision Plan Participants-Find a Provider and process
                          claims.
                        </p>
                        <a
                          href="https://www.aetna.com/about-us/login.html"
                          target="_blank"
                          rel="noreferrer"
                          className="blue-btn border-none"
                          type="submit"
                        >
                          Login
                        </a>
                        <button className="blue-btn border-none" type="submit">
                          Login
                        </button>
                      </div> */}

                      <div
                        className="blogin-container text-center"
                        style={{ position: "relative" }}
                      >
                        <img className="img-fluid" src={Unum} alt="unum" />
                        <p>
                          Life and AD&D Login – Understand your coverage, access
                          forms, and file a claim.
                        </p>
                        <a
                          href="https://services.unum.com/_fwLogin/"
                          target="_blank"
                          rel="noreferrer"
                          className="blue-btn border-none"
                          type="submit"
                        >
                          Login
                        </a>
                      </div>

                      <div
                        className="blogin-container text-center"
                        style={{ position: "relative" }}
                      >
                        <img
                          className="img-fluid"
                          src={LTTrust}
                          alt="LT Trust"
                        />
                        <p>
                          401(k) Participants – Manage your elections and
                          contributions.
                        </p>
                        <a
                          href="https://401k.ltretire.com/"
                          target="_blank"
                          rel="noreferrer"
                          className="blue-btn border-none"
                          type="submit"
                        >
                          Login
                        </a>
                      </div>
                    </Swiper>
                  </div>
                </div>
              </div>
            </LazyLoadComponent>
          </Container>
        </Container>

        <Container
          fluid
          className="benefit-tech text-center"
          style={{ padding: "100px 0" }}
        >
          <Container>
            <h2 className="text-white">
              Ready to Take Your Career to New Heights?
            </h2>
            <h3
              className="text-center btech-flair mb-5 text-white text-center"
              style={{ maWidth: "33em", margin: "0 auto" }}
            >
              Get started with PeerSource today.
            </h3>
            <div className="row">
              <div className="col-md-6">
                <Link
                  className="white-btn border-none"
                  to="/jobs"
                  style={{ fontSize: "20px" }}
                >
                  Explore Opportunities
                </Link>
              </div>{" "}
              <div className="col-md-6">
                <Link
                  className="white-btn border-none"
                  to="/contact-us"
                  style={{ fontSize: "20px" }}
                >
                  Contact a Recruiter
                </Link>
              </div>
            </div>
          </Container>
        </Container>
      </div>
    </Layout>
  )
}

export default TechnologyBenefits
